<template>
  <div class="flex column">
    <span class="box ellipsis">{{row.name}}</span>
    <span class="box fs-10 ellipsis info-text"
      v-for="(name, index) in row.desc"
      :key="index + '-name'">{{name.slice(0,4) === 'mem:' ? name.slice(4) : name}}</span>
  </div>
</template>

<script>
export default {
  name: 'BasketDescWaiver',

  props: {
    row: {
      type: Object,
      default: function () {
        return {}
      }
    }
  }
}
</script>
