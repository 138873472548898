<template>
  <div class="flex column">
    <span class="box ellipsis">{{row.name}}</span>
    <span class="box fs-10 ellipsis o-050"
      v-for="(name, index) in row.desc"
      :key="index + '-desc'">{{name}}</span>
    <span class="box fs-10 ellipsis info-text" v-if="row.customerName !== ''">{{row.customerName}}</span>
  </div>
</template>

<script>
export default {
  name: 'BasketDescLesson',

  props: {
    row: {
      type: Object,
      default: function () {
        return {}
      }
    }
  }
}
</script>
