<template>
    <div class="flex">
      <div class="box grow flex grid-12">
          <div class="col-5 mr-14" style="max-width: 450px;">
              <app-basket
                :members="members"
                :basket="basket"
                v-on:onClickCash="onClickCash"
                v-on:onClickCard="onClickCard"
                v-on:onAddWaiver="onAddWaiver"
                v-on:onClickInvoice="onClickInvoice"></app-basket>
          </div>
          <div class="col-7 flex column">
              <router-view
                :clearInputHome="clearInputHome"
                :members="members"
                :basket="basket"></router-view>
          </div>
      </div>
        <el-dialog title="Сonfirmation of payment" :visible.sync="dialogConfirmVisible">
          <div class="fs-18">
            <span class="">Are you sure you want to deposit <strong class="accent-text pl-5">by cash</strong> to pay the bill</span>
          </div>
          <div class="mt-16">
            <el-form label-position="left" label-width="100px" :model="form">
              <el-form-item label="Amount">
                <el-input-number class="pay-left" :min="0" :max="left" size="small" :controls="false" v-model="form.pay"></el-input-number>
              </el-form-item>
              <el-form-item label="Deposit">
                <el-input-number @focus="onFocusDeposit" ref='deposit' @input.native="onInputDeposit" class="pay-left" :min="0" size="small" :controls="false" :value="form.deposit"></el-input-number>
              </el-form-item>
              <el-form-item label="Change">
                <el-input-number class="pay-left" size="small" :controls="false" :value="Math.ceil(form.deposit * 100 - form.pay * 100) / 100" disabled></el-input-number>
              </el-form-item>
            </el-form>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogConfirmVisible = false">Cancel</el-button>
            <el-button type="primary" @click="ConfirmPayment({ isCash: true })">Confirm</el-button>
          </span>
        </el-dialog>

        <el-dialog title="Сonfirmation of payment" :visible.sync="dialogCardVisible">
          <div class="fs-18">
            <div class="">Are you sure you want to deposit <strong class="accent-text pl-5">by card</strong> to pay the bill</div>
          </div>
          <div class="mt-16">
            <el-input-number :min="0" :max="left" class="pay-left ph-9" size="small" :controls="false" v-model="form.pay"></el-input-number>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogCardVisible = false">Cancel</el-button>
            <el-button type="primary" @click="ConfirmPayment({ isCash: false })">Confirm</el-button>
          </span>
        </el-dialog>

        <el-dialog title="Attention! You are about to make a huge mistake!" :visible.sync="dialogInvoiceVisible">
          <div class="fs-18">Are you sure you want to close this Invoice?
            <br>Payment of  <strong class="accent-text pl-5">{{invoicePaid}} AED</strong> was received,
            <br> but there is an amount outstanding.
            <br>The invoice is not paid.
            <br>Please inform the head of operations of this wrongdoing!
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogInvoiceVisible = false">Cancel</el-button>
            <el-button type="primary" @click="ConfirmNewInvoice">Confirm</el-button>
          </span>
        </el-dialog>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import AppBasket from './sales/Basket'

export default {
  name: 'Operator',

  components: {
    AppBasket
  },

  data () {
    return {
      totalbyCompany: {},
      total: 0,
      paid: 0,
      left: 0,
      invoicePaid: 0,
      clearInputHome: false,
      dialogConfirmVisible: false,
      dialogCardVisible: false,
      dialogInvoiceVisible: false,
      settings: {
        maxScrollbarLength: 60
      },
      form: {
        pay: 0,
        deposit: 0
      }
    }
  },

  computed: {
    ...mapState({
      basket: state => state.basket.basket,
      currentInvoice: state => state.invoice.currentInvoice,
      types: state => state.type.types,
      members: state => state.members.members
    }),
    ...mapGetters([
      'getItemById',
      'getEventById',
      'getSubscriptionById',
      'getLessonById',
      'currentInvoiceUid',
      'today',
      'todaySessions',
      'getGroupIdByName'
    ])
  },

  methods: {
    ...mapActions([
      'clearBasket',
      'addInvoice',
      'addTicket',
      'addMemTransaction',
      'editEvent',
      'addTransaction',
      'closeInvoice',
      'editInvoice',
      'editBook',
      'editMember'
    ]),
    onFocusDeposit () {
      this.form.deposit = ''
    },
    onInputDeposit (evt) {
      let val = evt.target.value
      let integer = parseInt(val)
      this.form.deposit = integer
    },
    onClickCash ({ total, paid, left, totalbyCompany }) {
      this.totalbyCompany = totalbyCompany
      this.total = total
      this.paid = paid
      this.left = left
      this.form.pay = left
      this.form.deposit = left
      this.dialogConfirmVisible = true
    },
    onClickCard ({ total, paid, left, totalbyCompany }) {
      this.totalbyCompany = totalbyCompany
      this.total = total
      this.paid = paid
      this.left = left
      this.form.pay = left
      this.form.deposit = left
      this.dialogCardVisible = true
    },
    onAddWaiver () {
      this.clearInputHome = !this.clearInputHome
    },
    onClickInvoice ({ paid }) {
      if (paid > 0) {
        this.dialogInvoiceVisible = true
        this.invoicePaid = paid
      }
    },
    ConfirmNewInvoice () {
      this.clearBasket()
        .then(() => {
          return this.addInvoice()
        })
        .then(() => {
          this.dialogInvoiceVisible = false
        })
        .then(() => {
          this.$onCommandParams('home')
        })
    },
    async ConfirmPayment ({ isCash }) {
      await this.basket.forEach(async (row) => {
        if (row.times > 0) {
          switch (row.category) {
            case 'Books':
              let modelBook = {
                name: row.name,
                clientIds: row.clientIds,
                clientNames: row.clientNames,
                invoiceId: this.currentInvoiceUid,
                saleDate: Date.now(),
                day: this.today,
                sessionIds: row.sessionIdx,
                sessionTime: row.sessionTime,
                sessionName: row.sessionName,
                cost: row.times * row.rate,
                prepaid: 0,
                persons: row.times,
                isBackToBack: false,
                isDoubleSession: false,
                isBooking: true,
                typeId: row.typeIdx,
                typeName: row.typeName,
                discountType: '',
                discountPercent: 0,
                discountMemo: '',
                customerId: row.customerId,
                customerName: row.customerName,
                isClose: true
              }

              await this.editBook({
                id: row.bookId,
                content: {
                  useStatus: 'used'
                }
              })

              await this.addTicket(modelBook)

              if (row.hasOwnProperty('addons')) {
                await row.addons.forEach(async a => {
                  if (!this.getItemById[a._id].isRent) {
                    let modelAddons = {
                      name: a.name,
                      itemId: a._id,
                      count: 0 - a.count,
                      price: 0,
                      eventType: 'book',
                      memo: 'Invoice #' + this.currentInvoiceUid,
                      date: Date.now()
                    }
                    await this.addTransaction(modelAddons)
                  }
                })
              }

              break
            case 'Tickets':
              let modelTicket = {
                name: row.name,
                clientIds: row.clientIds,
                clientNames: row.clientNames,
                invoiceId: this.currentInvoiceUid,
                saleDate: Date.now(),
                day: this.today,
                sessionIds: row.sessionIdx2 !== -1 ? [row.sessionIdx, row.sessionIdx2] : [row.sessionIdx],
                sessionTime: row.sessionTime,
                sessionName: row.sessionName,
                cost: row.times * row.rate,
                prepaid: 0,
                persons: row.times,
                isBackToBack: row.isBackToBack,
                isDoubleSession: row.isDoubleSession,
                isBooking: row.isBooking,
                typeId: row.typeIdx,
                typeName: row.typeName,
                discountType: row.discountType,
                discountPercent: row.discountPercent,
                discountMemo: row.discountMemo,
                isClose: true
              }
              await this.addTicket(modelTicket)
              if (row.discountType === 'Subscription') {
                let modelSubscription = {
                  property: 'subscription',
                  memberId: row.clientIds[0],
                  count: 0 - 1,
                  eventType: 'Use Ticket',
                  memo: 'Invoice #' + this.currentInvoiceUid
                }
                await this.addMemTransaction(modelSubscription)
              }
              if (row.discountType === 'Lesson') {
                let modelLesson = {
                  property: 'lesson',
                  memberId: row.clientIds[0],
                  count: 0 - 1,
                  eventType: 'Use Ticket',
                  memo: 'Invoice #' + this.currentInvoiceUid
                }
                await this.addMemTransaction(modelLesson)
              }
              if (row.discountType === 'Group') {
                await this.editMember({
                  id: row.clientIds[0],
                  content: {
                    group: this.getGroupIdByName[row.discountMemo]
                  }
                })
              }
              break
            case 'Lessons':
              let lesson = this.getLessonById[row.eventId]
              let modelLesson = {
                property: 'lesson',
                memberId: row.customerId,
                count: lesson.duration,
                eventType: 'sale',
                memo: 'Invoice #' + this.currentInvoiceUid
              }
              await this.addMemTransaction(modelLesson)
              break
            case 'Subscriptions':
              let subscription = this.getSubscriptionById[row.eventId]
              let modelSubscription = {
                property: 'subscription',
                memberId: row.customerId,
                count: subscription.duration,
                eventType: 'sale',
                memo: 'Invoice #' + this.currentInvoiceUid
              }
              await this.addMemTransaction(modelSubscription)
              break
            case 'Events':
              let event = this.getEventById[row.eventId]
              let contentEvents = Object.assign({}, event, { prepaid: event.prepaid + row.rate, isClose: event.prepaid + row.rate >= event.cost })
              this.editEvent({ id: row.eventId, content: contentEvents })
              break
            case 'Addons':
              if (!this.getItemById[row.itemId].isRent) {
                let modelAddons = {
                  name: row.name,
                  itemId: row.itemId,
                  count: 0 - row.times,
                  price: row.rate,
                  eventType: 'sale',
                  memo: 'Invoice #' + this.currentInvoiceUid,
                  date: Date.now()
                }
                await this.addTransaction(modelAddons)
              }
              break
            case 'Merch':
            case 'Snacks':
              let modelMerch = {
                name: row.name,
                itemId: row.itemId,
                count: 0 - row.times,
                price: row.rate,
                eventType: 'sale',
                memo: 'Invoice #' + this.currentInvoiceUid,
                date: Date.now()
              }
              await this.addTransaction(modelMerch)
              break
          }
        }
      })
      let cash = this.currentInvoice.hasOwnProperty('totalByPaymentMethod') &&
      this.currentInvoice.totalByPaymentMethod.hasOwnProperty('cash')
        ? this.currentInvoice.totalByPaymentMethod.cash : 0
      let card = this.currentInvoice.hasOwnProperty('totalByPaymentMethod') &&
      this.currentInvoice.totalByPaymentMethod.hasOwnProperty('card')
        ? this.currentInvoice.totalByPaymentMethod.card : 0

      if (this.form.pay === this.left) {
        await this.closeInvoice({
          id: this.currentInvoiceUid,
          content: {
            basket: this.basket.filter(b => b.times > 0),
            total: this.total,
            totalByCompanies: this.totalbyCompany,
            totalByPaymentMethod: {
              cash: isCash ? cash + this.form.pay : cash,
              card: isCash ? card : card + this.form.pay
            },
            paid: this.currentInvoice.hasOwnProperty('paid') ? this.currentInvoice.paid + this.form.pay : this.form.pay,
            customerId: this.currentInvoice.customerId || '',
            customerName: this.currentInvoice.customerName || '',
            customerFirst: this.currentInvoice.customerFirst || '',
            customerEmail: this.currentInvoice.customerEmail || ''
          }
        })
          .then(() => {
            return this.clearBasket()
          })
          .then(() => {
            if (isCash) {
              this.dialogConfirmVisible = false
            } else {
              this.dialogCardVisible = false
            }
          })
          .then(() => {
            this.$onCommandParams('home')
          })
      } else {
        await this.editInvoice({
          id: this.currentInvoiceUid,
          content: {
            basket: this.basket,
            total: this.total,
            totalByCompanies: this.totalbyCompany,
            totalByPaymentMethod: {
              cash: isCash ? cash + this.form.pay : cash,
              card: isCash ? card : card + this.form.pay
            },
            paid: this.currentInvoice.hasOwnProperty('paid')
              ? this.currentInvoice.paid + this.form.pay
              : this.form.pay,
            customerId: this.currentInvoice.customerId || '',
            customerName: this.currentInvoice.customerName || '',
            customerFirst: this.currentInvoice.customerFirst || '',
            customerEmail: this.currentInvoice.customerEmail || ''
          }
        }).then(() => {
          if (isCash) {
            this.dialogConfirmVisible = false
          } else {
            this.dialogCardVisible = false
          }
        })
      }
    }
  }
}
</script>
