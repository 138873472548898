<template>
    <el-card class="ph-5 mr-15">
       <div class="invoice-top mb-20 flex justify-space-between">
           <div class="box self-flex-end ml-10">
              <h3 class="mv-0">Invoice #{{currentInvoiceUid}}</h3>
               <p class="o-050 mv-0">{{dateNow}}</p>
               <p v-if="currentInvoice.hasOwnProperty('customerName') && currentInvoice.customerName !== ''"
                class="mv-0">Customer: <span class="info-text">{{currentInvoice.customerName}}</span></p>
           </div>
           <div class="box self-flex-end mr-10" v-if="basketDate.length > 0">
             <el-button type="text" size="small" @click="newInvoice">New Invoice</el-button>
           </div>
       </div>
       <div class="invoice-bottom">
             <el-table
                ref="basket"
                :row-class-name="tableRowClassName"
                @cell-click="cellClick"
                :max-height="basketHeight"
                :data="basketDate"
                style="width: 100%;">
                <el-table-column
                    width="28"
                    class-name="hint-text"
                    type="index">
                </el-table-column>
                <el-table-column
                    width="120"
                    label="Desc">
                    <template #default="scope">
                      <app-desc-ticket
                        :row="scope.row"
                        v-if="scope.row.category === 'Tickets' ||
                          scope.row.category === 'Books'"></app-desc-ticket>
                      <app-desc-waiver
                        :row="scope.row"
                        v-if="scope.row.category === 'Waivers'"></app-desc-waiver>
                      <app-desc-lesson
                        :row="scope.row"
                        v-if="['Lessons', 'Subscriptions'].includes(scope.row.category)"></app-desc-lesson>
                      <app-desc-snack
                        :row="scope.row"
                        v-if="['Snacks', 'Merch', 'Addons'].includes(scope.row.category)"></app-desc-snack>
                    </template>
                </el-table-column>
                <el-table-column
                    width="65"
                    align="right"
                    prop="times"
                    label="times">
                </el-table-column>
                <el-table-column
                    width="70"
                    align="right"
                    prop="rate"
                    label="rate">
                    <template #default="scope">
                      <div v-if="scope.row.category !== 'Waivers' && scope.row.rate > 0">
                        <div>{{scope.row.rate}}</div>
                        <div class="warning-text fs-12">{{printWithOutTax(scope.row.rate)}}</div>
                        <div class="warning-text fs-12">{{printTax(scope.row.rate)}}</div>
                      </div>
                    </template>
                </el-table-column>
                <el-table-column
                    width="70"
                    align="right"
                    label="total">
                    <template #default="scope">
                      <div v-if="scope.row.category !== 'Waivers' && scope.row.rate > 0">
                        <div>{{scope.row.rate * scope.row.times}}</div>
                        <div class="warning-text fs-12">{{printWithOutTax(scope.row.rate * scope.row.times)}}</div>
                        <div class="warning-text fs-12">{{printTax(scope.row.rate * scope.row.times)}}</div>
                      </div>
                    </template>
                </el-table-column>
                <el-table-column
                    column-key="delete"
                    width="65"
                    align="right">
                    <template>
                        <el-button type="text" icon="el-icon-delete"></el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="flex gaps justify-flex-end mr-15" v-if="basketDate.length > 0">
              <div class="box flex column">
                <div class="box justify-flex-end flex gaps">
                  <div class="box justify-flex-end" v-for="(val, name, index) in countTotalbyCompany" :key="index">
                    <div class="flex gaps" v-if="val > 0">
                      <div class="box o-050"><h3 class="mv-6">{{name}}: </h3></div>
                      <div class="box payment"><h3 class="mv-6">{{val}}</h3></div>
                    </div>
                  </div>
                </div>
                <div class="box flex gaps justify-flex-end">
                  <div class="box o-050"><h3 class="mv-6">Paid: </h3></div>
                  <div class="box payment"><h3 class="mv-6">{{currentInvoice.hasOwnProperty('paid') ? currentInvoice.paid : 0}} AED</h3></div>
                </div>
                <div class="box flex gaps justify-flex-end">
                  <div class="box">
                    <h3 class="mv-6  o-050">Outstanding: </h3>
                    <div v-if="countTotal !== 0"  class="mv-6 fs-14 warning-text text-right">
                      Price:
                    </div>
                    <div v-if="countTotal !== 0" class="mv-6 fs-14 warning-text text-right">
                      VAT:
                    </div>
                  </div>
                  <div class="box payment">
                    <h3 class="mv-6">{{currentInvoice.hasOwnProperty('paid') ? Math.ceil(countTotal * 100 - currentInvoice.paid * 100) / 100 : countTotal}} AED</h3>
                    <div v-if="countTotal !== 0"  class="mv-6 fs-14 warning-text">
                      {{printWithOutTax(currentInvoice.hasOwnProperty('paid') ? Math.ceil(countTotal * 100 - currentInvoice.paid * 100) / 100 : countTotal)}}
                    </div>
                    <div v-if="countTotal !== 0" class="mv-6 fs-14 warning-text rigth">
                      {{printTax(currentInvoice.hasOwnProperty('paid') ? Math.ceil(countTotal * 100 - currentInvoice.paid * 100) / 100 : countTotal)}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="legalcopy flex justify-flex-end mr-15" v-if="basketDate.length > 0">
               <el-button type="primary" @click="onClickCard()">Card</el-button>
               <el-button type="primary" @click="onClickCash()" plain>Сash</el-button>
           </div>
       </div>
    </el-card>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import AppDescTicket from './components/BasketDescTicket'
import AppDescWaiver from './components/BasketDescWaiver'
import AppDescLesson from './components/BasketDescLesson'
import AppDescSnack from './components/BasketDescSnack'

export default {
  name: 'Basket',

  components: {
    AppDescTicket,
    AppDescWaiver,
    AppDescLesson,
    AppDescSnack
  },

  props: {
    members: {
      type: Array,
      default: function () {
        return []
      }
    },
    basket: {
      type: Array,
      default: function () {
        return []
      }
    }
  },

  data () {
    return {
      dateNow: '',
      buffer: {
        date: -1,
        text: ''
      }
    }
  },

  computed: {
    ...mapState({
      innerHeight: state => state.nav.innerHeight,
      currentInvoice: state => state.invoice.currentInvoice
    }),
    ...mapGetters([
      'getItemByCode',
      'snacksCategoryId',
      'merchCategoryId',
      'addonsCategoryId',
      'currentInvoiceUid',
      'getItemCompanyByUid',
      'getCategoryNameById'
    ]),
    basketDate () {
      return this.basket.filter(b => b.times > 0)
    },
    countTotal () {
      return this.basket.reduce((a, c) => {
        return Math.ceil(c.times * c.rate * 100 + a * 100) / 100
      }, 0)
    },
    countTotalbyCompany () {
      return this.basket.reduce((r, a) => {
        if (a.hasOwnProperty('companyName')) {
          r[a.companyName] = r[a.companyName] || 0
          r[a.companyName] = Math.ceil(r[a.companyName] * 100 + a.times * a.rate * 100) / 100
        }
        return r
      }, {})
    },
    basketHeight () {
      return this.innerHeight - 360 - 50
    }
  },
  methods: {
    ...mapActions([
      'clearBasket',
      'addInvoice',
      'remoteBasketItem',
      'editBasketItem',
      'createBasketItem',
      'addWaiwerToBasket',
      'clearLastMembers'
    ]),
    printWithOutTax (value) {
      return Math.ceil(value * 10000 / 105) / 100
    },
    printTax (value) {
      return Math.ceil(value * 100 - (this.printWithOutTax(value) * 100)) / 100
    },
    noWaiversCountMethod (row) {
      let persons = row.hasOwnProperty('personUids') ? row.personUids.length : 0
      let person = row.hasOwnProperty('person') && row.person !== '' ? 1 : 0
      return row.times - persons - person
    },
    tableRowClassName ({ row, rowIndex }) {
      if ((row.category === 'Tickets' && row.basketId === this.$route.params.id) ||
       (row.category === 'Lessons' && row.basketId === this.$route.params.id) ||
       (row.category === 'Subscriptions' && row.basketId === this.$route.params.id) ||
       (row.category === 'Waivers' && this.$route.name === 'waivers') ||
       (row.category === 'Addons' && this.$route.name === 'saleAddons') ||
       (row.category === 'Events' && this.$route.name === 'saleEvent' && this.$route.params.id === row.eventId) ||
       (row.category === 'Merch' && this.$route.name === 'saleMerch') ||
       (row.category === 'Snacks' && this.$route.name === 'saleSnacks') ||
       (row.category === 'Books' && this.$route.name === 'saleBooks' && row.basketId === this.$route.params.id)) {
        return 'warning-row'
      } else {
        return ''
      }
    },
    newInvoice () {
      if (this.currentInvoice.hasOwnProperty('paid') && this.currentInvoice.paid > 0) {
        this.$emit('onClickInvoice', {
          paid: this.currentInvoice.hasOwnProperty('paid') ? this.currentInvoice.paid : 0
        })
      } else {
        this.clearBasket()
          .then(() => {
            this.addInvoice()
          })
          .then(() => {
            this.$onCommandParams('home')
          })
      }
    },
    pollData () {
      this.polling = setInterval(() => {
        let today = new Date()
        let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }
        this.dateNow = today.toLocaleString('en-GB', options)
        this.clearLastMembers()
      }, 1000 * 60)
    },
    cellClick (row, column, cell, event) {
      if (column.columnKey === 'delete') {
        this.remoteBasketItem({ basketId: row.basketId })
          .then(() => {
            this.$onCommandParams('home')
          })
      } else {
        switch (row.category) {
          case 'Tickets':
            this.$onCommandParams('saleTickets', { id: row.basketId })
            break
          case 'Lessons':
            this.$onCommandParams('saleLessons', { id: row.basketId })
            break
          case 'Subscriptions':
            this.$onCommandParams('saleSubscriptions', { id: row.basketId })
            break
          case 'Events':
            this.$onCommandParams('saleEvent', { id: row.eventId })
            break
          case 'Addons':
            this.$onCommandParams('saleAddons')
            break
          case 'Merch':
            this.$onCommandParams('saleMerch')
            break
          case 'Snacks':
            this.$onCommandParams('saleSnacks')
            break
          case 'Waivers':
            this.$onCommandParams('waivers')
            break
          case 'Books':
            this.$onCommandParams('saleBooks', { id: row.basketId })
            break
        }
      }
    },
    keyDown: function (e) {
      const today = new Date()
      if (this.buffer.text === '') {
        this.buffer.date = today.getTime()
        this.buffer.text = this.buffer.text + '' + e.key
      } else if (e.key === 'ArrowDown' && this.buffer.text.slice(-5) === 'Enter') {
        if (this.buffer.text.slice(0, 7) === 'rolldxb') {
          this.scanWaiverCode(this.buffer.text.slice(14).slice(0, -5))
        } else if (this.buffer.text.slice(0, 7) === 'ShiftRo') {
          this.scanWaiverCode(this.buffer.text.slice(34).slice(0, -5))
        } else {
          this.scanBarCode(this.buffer.text.slice(0, -5))
        }
        Object.assign(this.buffer, {
          date: -1,
          text: ''
        })
      } else if (this.buffer.date > today.getTime() - 1000) {
        this.buffer.text = this.buffer.text + '' + e.key
      } else {
        Object.assign(this.buffer, {
          date: -1,
          text: ''
        })
      }
    },
    scanBarCode (code) {
      this.onAddWaiver()
      let item = this.getItemByCode[code]
      if (item !== undefined) {
        if ([this.snacksCategoryId, this.merchCategoryId, this.addonsCategoryId].includes(item.category)) {
          let coincidence = this.basket.find(el => el.addonId === item.uid)
          if (coincidence !== undefined) {
            this.editBasketItem({
              basketId: coincidence.basketId,
              content: {
                times: coincidence.times + 1
              }
            })
          } else {
            let company = this.getItemCompanyByUid[item.uid]
            this.createBasketItem({
              category: this.getCategoryNameById[item.category],
              addonId: item.uid,
              name: item.name,
              desc: [item.description],
              times: 1,
              rate: item.cost,
              companyId: company._id,
              companyName: company.name
            })
          }
        }
      }
    },
    scanWaiverCode (code) {
      this.onAddWaiver()
      this.addWaiwerToBasket({ code: code, isRemove: false })
    },
    onClickCash () {
      this.$emit('onClickCash', {
        totalbyCompany: this.countTotalbyCompany,
        total: this.countTotal,
        paid: this.currentInvoice.hasOwnProperty('paid') ? this.currentInvoice.paid : 0,
        left: this.currentInvoice.hasOwnProperty('paid') ? Math.ceil(this.countTotal * 100 - this.currentInvoice.paid * 100) / 100 : this.countTotal })
    },
    onClickCard () {
      this.$emit('onClickCard', {
        totalbyCompany: this.countTotalbyCompany,
        total: this.countTotal,
        paid: this.currentInvoice.hasOwnProperty('paid') ? this.currentInvoice.paid : 0,
        left: this.currentInvoice.hasOwnProperty('paid') ? Math.ceil(this.countTotal * 100 - this.currentInvoice.paid * 100) / 100 : this.countTotal })
    },
    onAddWaiver () {
      this.$emit('onAddWaiver')
    }
  },

  created: function () {
    window.addEventListener('keydown', this.keyDown)
    this.pollData()
  },

  beforeDestroy () {
    clearInterval(this.polling)
  },

  destroyed: function () {
    window.removeEventListener('keydown', this.keyDown)
  }
}
</script>

<style lang="scss">
</style>
