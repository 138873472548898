<template>
  <div class="flex column">
    <span class="box ellipsis">{{row.name}}</span>
    <span class="box fs-10 ellipsis warning-text"
      v-if="row.discountPercent > 0">Discount: {{row.discountPercent}}%</span>
    <span class="box fs-12 ellipsis">{{row.sessionName}}</span>
    <span class="box fs-12 ellipsis">{{row.sessionTime}}</span>
    <span class="box fs-10 ellipsis o-050">{{row.typeName}}</span>
    <template v-if="row.hasOwnProperty('clientNames')">
      <template v-if="row.clientNames.length > 0">
        <span class="box fs-10 ellipsis info-text"
          v-for="(name, index) in row.clientNames"
          :key="index + '-name'">{{name}}</span>
      </template>
      <template v-if="row.times - row.clientNames.length > 0">
        <span class="box fs-10 ellipsis danger-text"
          v-for="(i, index) in (row.times - row.clientNames.length)"
          :key="index + '-no-waiver'">No waiver</span>
      </template>
    </template>
    <template v-else>
      <template v-if="row.hasOwnProperty('person') && row.person !== ''">
        <span class="info-text">{{getClient(row.desc)}}</span>
      </template>
      <template v-if="row.hasOwnProperty('personNames')">
        <span class="info-text"
          v-for="name in row.personNames" :key="name">{{name}}</span>
      </template>
      <template v-if="noWaiversCountMethod(row) > 0">
        <span class="danger-text"
          v-for="(i, index) in noWaiversCountMethod(row)" :key="i + 'no waiver' + index">No waiver</span>
      </template>
    </template>

 </div>
</template>

<script>
export default {
  name: 'BasketDescTicket',

  props: {
    row: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  methods: {
    noWaiversCountMethod (row) {
      let persons = row.hasOwnProperty('personUids') ? row.personUids.length : 0
      let person = row.hasOwnProperty('person') && row.person !== '' ? 1 : 0
      return row.times - persons - person
    },
    getClient (descArray) {
      let client = descArray.find(d => d.slice(0, 4) === 'mem:')
      return client ? client.slice(4) : ''
    }
  }
}
</script>
